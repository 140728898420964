import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { currentPackageDatasourceURL } from "ee/RouteBuilder";
import history, { NavigationMethod } from "utils/history";
import { getCurrentWorkspaceId } from "ee/selectors/selectedWorkspaceSelectors";
import { fetchWorkspace } from "ee/actions/workspaceActions";
import useCurrentPackageState from "../hooks";
import { BottomButtons } from "../constants";
import { IDESidebar } from "IDE";
import { TopButtons } from "ee/entities/IDE/constants";

function Sidebar() {
  const dispatch = useDispatch();
  const appState = useCurrentPackageState();
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);

  useEffect(() => {
    dispatch(fetchWorkspace(currentWorkspaceId));
  }, [currentWorkspaceId]);

  const onClick = useCallback((suffix) => {
    const url = currentPackageDatasourceURL({ suffix });

    history.push(url, {
      invokedBy: NavigationMethod.PackageSidebar,
    });
  }, []);

  return (
    <IDESidebar
      bottomButtons={BottomButtons}
      editorState={appState}
      id={"t--package-sidebar"}
      onClick={onClick}
      topButtons={TopButtons}
    />
  );
}

export default Sidebar;
