export * from "ce/pages/Editor/IDE/LeftPane/LibrarySidePane";
import { Tab, TabPanel, Tabs, TabsList } from "@appsmith/ads";
import { default as CE_LibrarySidePane } from "ce/pages/Editor/IDE/LeftPane/LibrarySidePane";
import { appLibrariesURL, appPackagesURL } from "ee/RouteBuilder";
import { matchAppLibrariesPath } from "constants/routes";
import { IDESidePaneWrapper } from "IDE";
import JSLibrariesSection from "pages/Editor/IDE/LeftPane/JSLibrariesSection";
import React, { useCallback } from "react";
import { type RouteComponentProps } from "react-router";
import PackagesSection from "./PackagesSection";
import styled from "styled-components";

enum LIBRARY_TABS {
  "PACKAGES" = "PACKAGES",
  "JS_LIBRARIES" = "JS_LIBRARIES",
}
const StyledTabPanel = styled(TabPanel)`
  margin-top: 0;
  height: 100%;
`;

const StyledTabs = styled(Tabs)`
  height: 100%;
`;

function getActiveTab(pathname: string): LIBRARY_TABS {
  if (matchAppLibrariesPath(pathname)) {
    return LIBRARY_TABS.JS_LIBRARIES;
  }

  return LIBRARY_TABS.PACKAGES;
}

const LibrarySidePane = (props: RouteComponentProps) => {
  const activeTab = getActiveTab(props.location.pathname);
  // const showQueryModule = useSelector(getShowQueryModule);

  const onTabChange = useCallback(
    (value: string) => {
      const path =
        value === LIBRARY_TABS.JS_LIBRARIES
          ? appLibrariesURL()
          : appPackagesURL();

      props.history.push(path);
    },
    [props.history],
  );

  // if (!showQueryModule) return <CE_LibrarySidePane />;
  // Return by default the libraries section until versioning with git is up
  return <CE_LibrarySidePane />;

  return (
    <IDESidePaneWrapper padded>
      <StyledTabs
        defaultValue={activeTab}
        onValueChange={onTabChange}
        value={activeTab}
      >
        <TabsList>
          <Tab value={LIBRARY_TABS.PACKAGES}>Packages</Tab>
          <Tab value={LIBRARY_TABS.JS_LIBRARIES}>Libraries</Tab>
        </TabsList>
        <StyledTabPanel value={LIBRARY_TABS.PACKAGES}>
          <PackagesSection />
        </StyledTabPanel>
        <StyledTabPanel value={LIBRARY_TABS.JS_LIBRARIES}>
          <JSLibrariesSection />
        </StyledTabPanel>
      </StyledTabs>
    </IDESidePaneWrapper>
  );
};

export default LibrarySidePane;
