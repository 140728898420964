import { Link, type LinkProps } from "@appsmith/ads";
import { PACKAGES_OVERVIEW_DOC } from "constants/ThirdPartyConstants";
import { createMessage, PACKAGE_EMPTY_STATE_HELP } from "ee/constants/messages";
import React from "react";
import styled from "styled-components";

interface PackageLearnLinkProps {
  kind?: LinkProps["kind"];
}

const StyledLink = styled(Link)`
  & > span {
    font-weight: 500 !important;
  }
`;

function PackageLearnLink({ kind = "primary" }: PackageLearnLinkProps) {
  return (
    <StyledLink kind={kind} startIcon="question" to={PACKAGES_OVERVIEW_DOC}>
      {createMessage(PACKAGE_EMPTY_STATE_HELP)}
    </StyledLink>
  );
}

export default PackageLearnLink;
