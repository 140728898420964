import type { FieldValues, RegisterOptions } from "react-hook-form";

export interface WorkflowSchedulerFormDataType {
  timezone: string;
  workflowScheduleMinute: string;
  workflowScheduleHour: string;
  workflowScheduleDOM: string;
  workflowScheduleMonth: string;
  workflowScheduleDOW: string;
}

export enum WokrflowSchedulerFormFields {
  timezone = "timezone",
  workflowScheduleMinute = "workflowScheduleMinute",
  workflowScheduleHour = "workflowScheduleHour",
  workflowScheduleDOM = "workflowScheduleDOM",
  workflowScheduleMonth = "workflowScheduleMonth",
  workflowScheduleDOW = "workflowScheduleDOW",
}

export interface WorkflowSchedulerFormConfigType {
  label: () => string;
  tooltip: () => string;
  placeholder: () => string;
  validationRules: Omit<
    RegisterOptions<FieldValues, WokrflowSchedulerFormFields>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}
