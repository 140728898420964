import React from "react";
import { useSelector } from "react-redux";
import { getCurrentWorkflowId } from "ee/selectors/workflowSelectors";
import { Divider, Text } from "@appsmith/ads";
import {
  TRIGGER_SETTINGS_SECTION_CONTENT_HEADER,
  createMessage,
} from "ee/constants/messages";
import styled from "styled-components";
import WebhookSettingsSection from "./TriggerSettings/WebhookSettingsSection";
import SchedulerSettingsSection from "./TriggerSettings/SchedulerSettingsSection";
import { useFeatureFlag } from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAG } from "ee/entities/FeatureFlag";

export const SectionTitle = styled(Text)`
  margin-top: 24px;
  margin-bottom: 24px;
`;

function TriggerWorkflowSettings() {
  const currentWorkflowId = useSelector(getCurrentWorkflowId);
  const isSchedulerEnabled = useFeatureFlag(
    FEATURE_FLAG.rollout_workflow_scheduler_enabled,
  );

  return (
    <div className="flex flex-col mx-[24px] w-[650px]">
      <SectionTitle kind="heading-m">
        {createMessage(TRIGGER_SETTINGS_SECTION_CONTENT_HEADER)}
      </SectionTitle>
      <WebhookSettingsSection currentWorkflowId={currentWorkflowId} />
      <Divider className="my-4" />
      {isSchedulerEnabled && (
        <SchedulerSettingsSection currentWorkflowId={currentWorkflowId} />
      )}
    </div>
  );
}

export default TriggerWorkflowSettings;
