import React from "react";
import { DocsMenuItem, usePluginActionContext } from "PluginActionEditor";
import { MenuSeparator } from "@appsmith/ads";
import { DeleteModuleMenuItem } from "./DeleteModuleMenuItem";
import { RenameMenuItem } from "IDE";

export const ToolbarMenu = () => {
  const { action } = usePluginActionContext();

  return (
    <>
      <RenameMenuItem entityId={action.id} />
      <DocsMenuItem />
      <MenuSeparator />
      <DeleteModuleMenuItem />
    </>
  );
};
