import React, { useCallback, useState } from "react";
import {
  CONFIRM_CONTEXT_DELETE,
  CONTEXT_DELETE,
  createMessage,
} from "ee/constants/messages";
import { MenuItem } from "@appsmith/ads";
import { useHandleDeleteClick } from "PluginActionEditor/hooks";

export const DeleteWorkflowMenuItem = () => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { handleDeleteClick } = useHandleDeleteClick();

  const onDeleteClick = useCallback(
    (e?: Event) => {
      e?.preventDefault();

      if (!confirmDelete) {
        setConfirmDelete(true);

        return;
      }

      handleDeleteClick({});
    },
    [confirmDelete],
  );

  return (
    <MenuItem
      className="t--apiFormDeleteBtn error-menuitem"
      onSelect={onDeleteClick}
      startIcon="trash"
    >
      {confirmDelete
        ? createMessage(CONFIRM_CONTEXT_DELETE)
        : createMessage(CONTEXT_DELETE)}
    </MenuItem>
  );
};
