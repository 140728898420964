import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { RouteComponentProps } from "react-router";

import Editor from "pages/Editor/QueryEditor/Editor";
import { changeQuery } from "PluginActionEditor/store";
import { QueryEditorContextProvider } from "pages/Editor/QueryEditor/QueryEditorContext";
import { getActionByBaseId } from "ee/selectors/entitiesSelector";
import ActionEditorContextMenu from "../ModuleEditor/ActionEditorContextMenu";
import history from "utils/history";
import { integrationEditorURL } from "ee/RouteBuilder";
import { INTEGRATION_TABS } from "constants/routes";
import { DatasourceCreateEntryPoints } from "constants/Datasource";
import AnalyticsUtil from "ee/utils/AnalyticsUtil";
import { getIsWorkflowEditorInitialized } from "ee/selectors/workflowSelectors";
import { saveWorkflowActionName } from "ee/actions/workflowActions";
import { deleteAction } from "actions/pluginActionActions";
import type { PluginType } from "entities/Action";
import { useWorkflowQuerySettings } from "ee/hooks/workflowHooks";

interface WorkflowQueryEditorRouteParams {
  basePageId: string; // TODO: @ashit remove this and add generic key in the Editor
  workflowId: string;
  baseQueryId?: string;
  baseApiId?: string;
}

type WorkflowQueryEditorProps =
  RouteComponentProps<WorkflowQueryEditorRouteParams>;

function WorkflowQueryEditor(props: WorkflowQueryEditorProps) {
  const dispatch = useDispatch();
  const { baseApiId, baseQueryId, workflowId } = props.match.params;

  const isWorkflowEditorInitialized = useSelector(
    getIsWorkflowEditorInitialized,
  );

  const baseActionId = baseQueryId || baseApiId || "";
  const action = useSelector((state) => getActionByBaseId(state, baseActionId));

  const settingsConfig = useWorkflowQuerySettings({
    pluginId: action?.pluginId || "",
    pluginType: (action?.pluginType as PluginType) || "",
  });

  const changeQueryPage = useCallback(
    (baseQueryId: string) => {
      dispatch(changeQuery({ baseQueryId, workflowId }));
    },
    [dispatch, workflowId],
  );

  const onCreateDatasourceClick = useCallback(() => {
    history.push(
      integrationEditorURL({
        workflowId,
        selectedTab: INTEGRATION_TABS.NEW,
      }),
    );
    // Event for datasource creation click
    const entryPoint = DatasourceCreateEntryPoints.QUERY_EDITOR;

    AnalyticsUtil.logEvent("NAVIGATE_TO_CREATE_NEW_DATASOURCE_PAGE", {
      entryPoint,
    });
  }, [workflowId]);

  // custom function to return user to integrations page if action is not found
  const onEntityNotFoundBackClick = useCallback(
    () =>
      history.push(
        integrationEditorURL({
          workflowId,
          selectedTab: INTEGRATION_TABS.ACTIVE,
        }),
      ),
    [workflowId],
  );

  const onDeleteWorkflowAction = useCallback(() => {
    dispatch(deleteAction({ id: action?.id ?? "", name: action?.name ?? "" }));
  }, [action?.id, action?.name, dispatch]);

  const moreActionsMenu = useMemo(() => {
    return (
      <ActionEditorContextMenu
        isDeletePermitted
        onDelete={onDeleteWorkflowAction}
      />
    );
  }, [onDeleteWorkflowAction]);

  const onSaveWorkflowActionName = useCallback(
    ({ id, name }: { id: string; name: string }) => {
      return saveWorkflowActionName({ id, name });
    },
    [],
  );

  return (
    <QueryEditorContextProvider
      changeQueryPage={changeQueryPage}
      moreActionsMenu={moreActionsMenu}
      onCreateDatasourceClick={onCreateDatasourceClick}
      onEntityNotFoundBackClick={onEntityNotFoundBackClick}
      saveActionName={onSaveWorkflowActionName}
    >
      <Editor
        {...props}
        isEditorInitialized={isWorkflowEditorInitialized}
        settingsConfig={settingsConfig}
      />
    </QueryEditorContextProvider>
  );
}

export default WorkflowQueryEditor;
