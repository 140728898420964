import React, { useCallback } from "react";
import { CONTEXT_DUPLICATE, createMessage } from "ee/constants/messages";
import { MenuItem } from "@appsmith/ads";
import { getCurrentWorkflowId } from "ee/selectors/workflowSelectors";
import { useSelector } from "react-redux";
import { useHandleDuplicateClick } from "PluginActionEditor/hooks";

export const DuplicateWorkflowMenuItem = () => {
  const workflowId = useSelector(getCurrentWorkflowId);
  const { handleDuplicateClick } = useHandleDuplicateClick();
  const onDuplicateClick = useCallback(
    (e?: Event) => {
      e?.preventDefault();

      handleDuplicateClick(workflowId || "");
    },
    [handleDuplicateClick, workflowId],
  );

  return (
    <MenuItem
      className="t--apiFormDuplicateBtn"
      onSelect={onDuplicateClick}
      startIcon="duplicate"
    >
      {createMessage(CONTEXT_DUPLICATE)}
    </MenuItem>
  );
};
