import React, { useMemo, useState } from "react";
import { Link, Switch, Text } from "@appsmith/ads";
import {
  createMessage,
  KNOW_MORE,
  TRIGGER_SETTINGS_SCHEDULE_SECTION_DESC,
  TRIGGER_SETTINGS_SCHEDULE_SECTION_HEADER,
  WORKFLOW_SCHEDULE_TIMEZONE_SELECT_LABEL,
} from "ee/constants/messages";
import Form from "./CRON/Form";
import {
  WokrflowSchedulerFormFields,
  type WorkflowSchedulerFormDataType,
} from "./CRON/types";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsWorkflowScheduleSaving,
  getWorkflowSchedules,
} from "ee/selectors/workflowSelectors";
import { WORKFLOW_SCHEDULE_FORM_DEFAULT_VALUES } from "./CRON/constants";
import { objectKeys } from "@appsmith/utils";
import { WorkflowScheduleState } from "ee/constants/WorkflowConstants";
import {
  saveWorkflowSchedule,
  toggleWorkflowScheduleState,
} from "ee/actions/workflowActions";

interface IProps {
  currentWorkflowId?: string;
}

export default function SchedulerSettingsSection({
  currentWorkflowId,
}: IProps) {
  const savedSchedules = useSelector(getWorkflowSchedules);
  const [savedScheduledId, setSavedScheduleId] = useState("");
  const [schedulerState, setSchedulerState] = useState(
    WorkflowScheduleState.INACTIVE,
  );
  const dispatch = useDispatch();
  const isWorkflowScheduleSaving = useSelector(getIsWorkflowScheduleSaving);

  const formDefaultValues: Partial<WorkflowSchedulerFormDataType> =
    useMemo(() => {
      if (savedSchedules) {
        const { schedules } = savedSchedules;

        if (!schedules) return WORKFLOW_SCHEDULE_FORM_DEFAULT_VALUES;

        const scheduleId = objectKeys(schedules)[0];
        const savedSchedule = schedules[scheduleId];
        const cronString = savedSchedule.scheduleSpec.cronExpressions[0];
        const scheduleParts = cronString.split(" ");
        const defaultValues: WorkflowSchedulerFormDataType = {
          [WokrflowSchedulerFormFields.timezone]:
            savedSchedule.scheduleSpec.timeZoneName,
          [WokrflowSchedulerFormFields.workflowScheduleMinute]:
            scheduleParts[0],
          [WokrflowSchedulerFormFields.workflowScheduleHour]: scheduleParts[1],
          [WokrflowSchedulerFormFields.workflowScheduleDOM]: scheduleParts[2],
          [WokrflowSchedulerFormFields.workflowScheduleMonth]: scheduleParts[3],
          [WokrflowSchedulerFormFields.workflowScheduleDOW]: scheduleParts[4],
        };

        setSchedulerState(savedSchedule.state);
        setSavedScheduleId(scheduleId);

        return defaultValues;
      } else {
        return WORKFLOW_SCHEDULE_FORM_DEFAULT_VALUES;
      }
    }, [savedSchedules]);

  function triggerScheduleSave(formData: WorkflowSchedulerFormDataType) {
    dispatch(
      saveWorkflowSchedule({
        workflowId: currentWorkflowId || "",
        scheduleId: savedScheduledId,
        schedulerState,
        formData,
      }),
    );
  }

  /**
   * function to handle toggle event of the schedule state swtich
   * A schedule can only be enabled if a workflow is saved.
   * When a schedule is created for the first time, the default state is disabled
   *
   * When a new workflow is created, there is no saved schedule.
   * This means when a user tries to enable a schedule on a new workflow, the action should be to create a schedule
   * which is enabled.
   * */
  function toggleScheduleState(newState: boolean) {
    if (savedScheduledId.length === 0 && newState) {
      dispatch(
        saveWorkflowSchedule({
          workflowId: currentWorkflowId || "",
          scheduleId: savedScheduledId,
          schedulerState: WorkflowScheduleState.ACTIVE,
          formData: WORKFLOW_SCHEDULE_FORM_DEFAULT_VALUES,
        }),
      );
    } else {
      dispatch(
        toggleWorkflowScheduleState({
          workflowId: currentWorkflowId || "",
          scheduleId: savedScheduledId,
          newState: newState
            ? WorkflowScheduleState.ACTIVE
            : WorkflowScheduleState.INACTIVE,
        }),
      );
    }
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-1 mb-2">
        <Switch
          data-testid="t--scheduler-status-switch"
          isDisabled={isWorkflowScheduleSaving}
          isSelected={schedulerState === WorkflowScheduleState.ACTIVE}
          onChange={toggleScheduleState}
        >
          <Text kind="heading-s">
            {createMessage(TRIGGER_SETTINGS_SCHEDULE_SECTION_HEADER)}
          </Text>
        </Switch>
        <div className="flex gap-1">
          <Text kind="body-m">
            {createMessage(TRIGGER_SETTINGS_SCHEDULE_SECTION_DESC)}
          </Text>
          <Link
            kind="secondary"
            to="https://app.appsmith.com/app/cron/home-66fac6586931e9259851a137?branch=master&embed=true"
          >
            {createMessage(KNOW_MORE)}
          </Link>
        </div>
      </div>
      <Text className="mt-1" kind="body-m">
        {createMessage(WORKFLOW_SCHEDULE_TIMEZONE_SELECT_LABEL)}
      </Text>
      <Form
        defaultValues={formDefaultValues}
        isWorkflowScheduleSaving={isWorkflowScheduleSaving}
        triggerScheduleSave={triggerScheduleSave}
      />
    </div>
  );
}
