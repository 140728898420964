import {
  MODULE_EDITOR_PATH,
  PACKAGE_EDITOR_PATH,
  PACKAGE_LIBRARIES_EDITOR_PATH,
} from "ee/constants/routes/packageRoutes";
import {
  DATA_SOURCES_EDITOR_ID_PATH,
  DATA_SOURCES_EDITOR_LIST_PATH,
  INTEGRATION_EDITOR_PATH,
} from "ee/constants/routes/appRoutes";
import ModuleEditor from "ee/pages/Editor/ModuleEditor";
import CreateNewDatasourceTab from "pages/Editor/IntegrationEditor/CreateNewDatasourceTab";
import DataSourceEditor from "pages/Editor/DataSourceEditor";
import DatasourceBlankState from "pages/Editor/DataSourceEditor/DatasourceBlankState";
import DatasourceForm from "pages/Editor/SaaSEditor/DatasourceForm";
import { SAAS_EDITOR_DATASOURCE_ID_PATH } from "pages/Editor/SaaSEditor/constants";

export const useRoutes = (path: string) => {
  return [
    {
      key: "Datasource Create and Active",
      component: CreateNewDatasourceTab,
      exact: true,
      path: `${path}${INTEGRATION_EDITOR_PATH}`,
    },
    {
      key: "DatasourceEditor",
      component: DataSourceEditor,
      exact: true,
      path: `${path}${DATA_SOURCES_EDITOR_ID_PATH}`,
    },
    {
      key: "DatasourceBlankState",
      component: DatasourceBlankState,
      exact: true,
      path: `${path}${DATA_SOURCES_EDITOR_LIST_PATH}`,
    },
    {
      key: "SAASDatasourceEditor",
      component: DatasourceForm,
      exact: true,
      path: `${path}${SAAS_EDITOR_DATASOURCE_ID_PATH}`,
    },
    {
      key: "ModuleEditor",
      component: ModuleEditor,
      path: `${MODULE_EDITOR_PATH}`,
    },
    {
      key: "ModuleEditorJSLibs",
      exact: true,
      component: undefined,
      path: `${PACKAGE_EDITOR_PATH}${PACKAGE_LIBRARIES_EDITOR_PATH}`,
    },
  ];
};
