import { Tooltip, Text, type TextProps } from "@appsmith/ads";
import fastdom from "fastdom";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const StyledText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const TruncatedTextWithTooltip = (props: TextProps) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    // Using FastDOM to check if the text is truncated
    fastdom.measure(() => {
      const element = textRef.current;

      if (element) {
        const isOverflowing = element.scrollWidth > element.clientWidth;

        // Update the state in a mutation step (FastDOM optimization)
        fastdom.mutate(() => {
          setIsTruncated(isOverflowing);
        });
      }
    });
  }, []);

  const textElement = <StyledText ref={textRef} {...props} />;

  if (isTruncated) {
    return (
      <Tooltip content={props.children} placement="top">
        {textElement}
      </Tooltip>
    );
  }

  return textElement;
};

export default TruncatedTextWithTooltip;
