import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { RouteComponentProps } from "react-router";
import { useHistory } from "react-router";
import { noop } from "lodash";

import ActionEditorContextMenu from "./ActionEditorContextMenu";
import Editor from "pages/Editor/QueryEditor/Editor";
import ModuleInputsForm from "./ModuleInputsForm";
import Loader from "./Loader";
import { changeQuery } from "PluginActionEditor/store";
import { getIsPackageEditorInitialized } from "ee/selectors/packageSelectors";
import { QueryEditorContextProvider } from "pages/Editor/QueryEditor/QueryEditorContext";
import { getModuleById } from "ee/selectors/modulesSelector";
import { deleteModule, saveModuleName } from "ee/actions/moduleActions";
import type { SaveModuleNamePayload } from "ee/actions/moduleActions";
import { builderURL, moduleEditorURL } from "ee/RouteBuilder";
import { getActionByBaseId } from "ee/selectors/entitiesSelector";
import { saveActionNameBasedOnParentEntity } from "ee/actions/helpers";
import { ActionParentEntityType } from "ee/entities/Engine/actionHelpers";
import { MODULE_EDITOR_TYPE, MODULE_TYPE } from "ee/constants/ModuleConstants";
import { useModuleFallbackSettingsForm } from "./ModulePluginActionEditor/hooks";
import type { PluginType } from "entities/Action";
import { deleteAction } from "actions/pluginActionActions";

interface ModuleQueryEditorRouteParams {
  basePageId: string; // TODO: @ashit remove this and add generic key in the Editor
  packageId: string;
  moduleId: string;
  baseQueryId?: string;
  baseApiId?: string;
}

type ModuleQueryEditorProps = RouteComponentProps<ModuleQueryEditorRouteParams>;

function ModuleQueryEditor(props: ModuleQueryEditorProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { baseApiId, baseQueryId, moduleId, packageId } = props.match.params;
  const baseActionId = baseQueryId || baseApiId || "";

  const isPackageEditorInitialized = useSelector(getIsPackageEditorInitialized);
  const module = useSelector((state) => getModuleById(state, moduleId));
  const action = useSelector((state) => getActionByBaseId(state, baseActionId));
  const fallbackSettings = useModuleFallbackSettingsForm({
    pluginId: action?.pluginId || "",
    pluginType: (action?.pluginType as PluginType) || "",
    interfaceType: "CREATOR",
  });
  const isEditorInitialized = isPackageEditorInitialized && Boolean(action);

  const changeQueryPage = (baseQueryId: string) => {
    dispatch(changeQuery({ baseQueryId, moduleId, packageId }));
  };

  const onSaveName = useCallback(
    ({ name }: SaveModuleNamePayload) => {
      const isPublicEntity = action?.isPublic;

      return isPublicEntity
        ? saveModuleName({
            id: moduleId,
            name,
          })
        : saveActionNameBasedOnParentEntity(
            action?.id ?? "",
            name,
            ActionParentEntityType.MODULE,
          );
    },
    [moduleId, action?.isPublic, action?.id],
  );

  const onCreateDatasourceClick = () => {
    history.push(
      builderURL({
        suffix: "datasources/NEW",
        generateEditorPath: true,
      }),
    );
  };

  const deleteActionFromModule = useCallback(() => {
    dispatch(
      deleteAction({
        id: action?.id || "",
        name: action?.name || "",
        onSuccess: () => history.push(moduleEditorURL({ moduleId })),
      }),
    );
  }, [action?.name, action?.id, dispatch, history, moduleId]);

  const deleteQueryModule = useCallback(() => {
    dispatch(deleteModule({ id: moduleId }));
  }, [dispatch, moduleId]);

  const onDelete = useCallback(() => {
    action?.isPublic ? deleteQueryModule() : deleteActionFromModule();
  }, [action?.isPublic, deleteActionFromModule, deleteQueryModule]);

  const moreActionsMenu = useMemo(() => {
    return <ActionEditorContextMenu isDeletePermitted onDelete={onDelete} />;
  }, [onDelete]);

  const actionRightPaneAdditionSections = useMemo(() => {
    if (!module?.inputsForm || !action?.isPublic) {
      return null;
    }

    return (
      <ModuleInputsForm
        defaultValues={{
          inputsForm: module?.inputsForm,
        }}
        moduleEditorType={MODULE_EDITOR_TYPE.QUERY}
        moduleId={module?.id}
      />
    );
  }, [module?.id, module?.inputsForm, action?.isPublic]);

  if (!isEditorInitialized) {
    return <Loader />;
  }

  const moduleSettings =
    module?.settingsForm.length === 0 ? fallbackSettings : module?.settingsForm;

  return (
    <QueryEditorContextProvider
      actionRightPaneAdditionSections={actionRightPaneAdditionSections}
      changeQueryPage={changeQueryPage}
      moreActionsMenu={moreActionsMenu}
      onCreateDatasourceClick={onCreateDatasourceClick}
      onEntityNotFoundBackClick={noop}
      saveActionName={onSaveName}
      showSuggestedWidgets={module?.type === MODULE_TYPE.UI}
    >
      <Editor
        {...props}
        isEditorInitialized={isEditorInitialized}
        settingsConfig={moduleSettings}
      />
    </QueryEditorContextProvider>
  );
}

export default ModuleQueryEditor;
