import React from "react";
import { PluginActionToolbar } from "PluginActionEditor";
import { saveWorkflowActionName } from "ee/actions/workflowActions";
import { PluginActionNameEditor } from "PluginActionEditor";
import { ToolbarMenu } from "./ToolbarMenu";

const WorkflowPluginActionToolbar = () => {
  return (
    <PluginActionToolbar menuContent={<ToolbarMenu />}>
      <PluginActionNameEditor saveActionName={saveWorkflowActionName} />
    </PluginActionToolbar>
  );
};

export default WorkflowPluginActionToolbar;
