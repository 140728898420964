import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import {
  CONTEXT_DELETE,
  CONFIRM_CONTEXT_DELETE,
  createMessage,
  CONTEXT_RENAME,
} from "ee/constants/messages";
import {
  autoIndentCode,
  getAutoIndentShortcutKeyText,
} from "components/editorComponents/CodeEditor/utils/autoIndentUtils";
import AnalyticsUtil from "ee/utils/AnalyticsUtil";
import { updateJSCollectionBody } from "actions/jsPaneActions";
import type { IconName } from "@blueprintjs/icons";

import {
  getHasDeleteActionPermission,
  getHasManageActionPermission,
} from "ee/utils/BusinessFeatures/permissionPageHelpers";
import { useFeatureFlag } from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAG } from "ee/entities/FeatureFlag";
import type { JSCollection } from "entities/JSCollection";
import {
  JSEditorContextMenu,
  type ContextMenuOption,
} from "pages/Editor/JSEditor/JSEditorContextMenu";
import { deleteJSCollection } from "actions/jsActionActions";
import { setRenameEntity } from "../../../../actions/ideActions";

interface WorkflowJSEditorContextMenuProps {
  jsCollection: JSCollection;
  workflowId: string;
}

const prettifyCodeKeyboardShortCut = getAutoIndentShortcutKeyText();

export function WorkflowJSEditorContextMenu({
  jsCollection,
}: WorkflowJSEditorContextMenuProps) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const dispatch = useDispatch();
  const isFeatureEnabled = useFeatureFlag(FEATURE_FLAG.license_gac_enabled);
  const isDeletePermitted = getHasDeleteActionPermission(
    isFeatureEnabled,
    jsCollection?.userPermissions || [],
  );
  const isChangePermitted = getHasManageActionPermission(
    isFeatureEnabled,
    jsCollection?.userPermissions || [],
  );

  const onDelete = () => {
    dispatch(
      deleteJSCollection({ id: jsCollection.id, name: jsCollection.name }),
    );
  };

  const confirmDeletion = (value: boolean, event?: Event) => {
    event?.preventDefault?.();
    setConfirmDelete(value);
  };

  const renameJS = useCallback(() => {
    // We add a delay to avoid having the focus stuck in the menu trigger
    setTimeout(() => {
      dispatch(setRenameEntity(jsCollection.id));
    }, 100);
  }, []);

  const renameOption = {
    icon: "input-cursor-move" as IconName,
    value: "rename",
    onSelect: renameJS,
    label: createMessage(CONTEXT_RENAME),
    disabled: !isChangePermitted,
  };

  const prettifyOptions = {
    value: "prettify",
    icon: "code" as IconName,
    subText: prettifyCodeKeyboardShortCut,
    onSelect: () => {
      /*
        PS: Please do not remove ts-ignore from here, TS keeps suggesting that
        the object is null, but that is not the case, and we need an
        instance of the editor to pass to autoIndentCode function
        */
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const editor = document.querySelector(".CodeMirror").CodeMirror;

      autoIndentCode(editor);
      dispatch(updateJSCollectionBody(editor.getValue(), jsCollection.id));
      AnalyticsUtil.logEvent("PRETTIFY_CODE_MANUAL_TRIGGER");
    },
    label: "Prettify code",
  };

  const deleteOption = {
    confirmDelete: confirmDelete,
    icon: "delete-bin-line" as IconName,
    value: "delete",
    onSelect: (event?: Event): void => {
      confirmDelete ? onDelete() : confirmDeletion(true, event);
    },
    label: confirmDelete
      ? createMessage(CONFIRM_CONTEXT_DELETE)
      : createMessage(CONTEXT_DELETE),
    className: "t--apiFormDeleteBtn error-menuitem",
  };

  const options: ContextMenuOption[] = [renameOption];

  if (isChangePermitted) {
    options.push(prettifyOptions);
  }

  if (isDeletePermitted) options.push(deleteOption);

  return (
    <JSEditorContextMenu
      className="t--more-action-menu"
      onMenuClose={() => {
        setConfirmDelete(false);
      }}
      options={options}
    />
  );
}

export default WorkflowJSEditorContextMenu;
