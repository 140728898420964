import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { noop } from "lodash";
import type { RouteComponentProps } from "react-router";

import JsEditorForm from "pages/Editor/JSEditor/Form";
import { getJSCollectionDataByBaseId } from "selectors/editorSelectors";
import { saveJSObjectNameBasedOnParentEntity } from "ee/actions/helpers";
import { ActionParentEntityType } from "ee/entities/Engine/actionHelpers";
import { getIsWorkflowEditorInitialized } from "ee/selectors/workflowSelectors";
import WorkflowJsEditorContextMenu from "./WorkflowJSEditorContextMenu";
import Loader from "../ModuleEditor/Loader";
import type { SaveActionNameParams } from "PluginActionEditor";

interface WorkflowJsEditorRouteParams {
  workflowId: string;
  baseCollectionId: string;
}

type WorkflowJsEditorProps = RouteComponentProps<WorkflowJsEditorRouteParams>;

function WorkflowJsEditor(props: WorkflowJsEditorProps) {
  const { baseCollectionId, workflowId } = props.match.params;
  const isWorkflowEditorInitialized = useSelector(
    getIsWorkflowEditorInitialized,
  );
  const jsCollectionData = useSelector((state) =>
    getJSCollectionDataByBaseId(state, baseCollectionId),
  );

  const jsCollection = jsCollectionData?.config;
  const contextMenu = useMemo(() => {
    if (!jsCollection) {
      return null;
    }

    return (
      <WorkflowJsEditorContextMenu
        jsCollection={jsCollection}
        workflowId={workflowId}
      />
    );
  }, [jsCollection, workflowId]);

  const onSaveName = useCallback(
    ({ name }: SaveActionNameParams) => {
      return saveJSObjectNameBasedOnParentEntity(
        jsCollection?.id ?? "",
        name,
        ActionParentEntityType.WORKFLOW,
      );
    },
    [jsCollection?.id],
  );

  if (!isWorkflowEditorInitialized || !jsCollection) {
    return <Loader />;
  }

  return (
    <JsEditorForm
      contextMenu={contextMenu}
      hideContextMenuOnEditor={Boolean(
        jsCollectionData?.config.isMainJSCollection,
      )}
      hideEditIconOnEditor={Boolean(
        jsCollectionData?.config.isMainJSCollection,
      )}
      jsCollectionData={jsCollectionData}
      onUpdateSettings={noop}
      saveJSObjectName={onSaveName}
      showNameEditor
      showSettings={false}
    />
  );
}

export default WorkflowJsEditor;
