import {
  createMessage,
  WORKFLOW_SCHEDULE_INPUT_DAY_OF_MONTH_LABEL,
  WORKFLOW_SCHEDULE_INPUT_DAY_OF_MONTH_PLACEHOLDER,
  WORKFLOW_SCHEDULE_INPUT_DAY_OF_WEEK_LABEL,
  WORKFLOW_SCHEDULE_INPUT_DAY_OF_WEEK_PLACEHOLDER,
  WORKFLOW_SCHEDULE_INPUT_HOUR_LABEL,
  WORKFLOW_SCHEDULE_INPUT_HOUR_PLACEHOLDER,
  WORKFLOW_SCHEDULE_INPUT_MINUTE_LABEL,
  WORKFLOW_SCHEDULE_INPUT_MINUTE_PLACEHOLDER,
  WORKFLOW_SCHEDULE_INPUT_MONTH_LABEL,
  WORKFLOW_SCHEDULE_INPUT_MONTH_PLACEHOLDER,
  WORKFLOW_SCHEDULE_INPUT_TOOLTIP,
  WORKFLOW_SCHEDULE_INPUT_VALIDATION_REGEX_ERROR,
  WORKFLOW_SCHEDULE_INPUT_VALIDATION_REQUIRED_ERROR,
  WORKFLOW_SCHEDULE_TIMEZONE_SELECT_LABEL,
} from "ee/constants/messages";
import {
  WokrflowSchedulerFormFields,
  type WorkflowSchedulerFormConfigType,
  type WorkflowSchedulerFormDataType,
} from "./types";

export const allTimeZoneOpts = {
  // UTC−12:00
  "Etc/GMT+12": "UTC-12:00",

  // UTC−11:00
  "Pacific/Midway": "UTC-11:00",
  "Pacific/Niue": "UTC-11:00",
  "Pacific/Pago_Pago": "UTC-11:00",
  "Etc/GMT+11": "UTC-11:00",

  // UTC−10:00
  "America/Adak": "UTC-10:00",
  "Pacific/Honolulu": "UTC-10:00",
  "Pacific/Rarotonga": "UTC-10:00",
  "Pacific/Tahiti": "UTC-10:00",
  "Etc/GMT+10": "UTC-10:00",

  // UTC−09:30
  "Pacific/Marquesas": "UTC-09:30",

  // UTC−09:00
  "America/Anchorage": "UTC-09:00",
  "Pacific/Gambier": "UTC-09:00",
  "Etc/GMT+9": "UTC-09:00",

  // UTC−08:00
  "America/Los_Angeles": "UTC-08:00",
  "America/Tijuana": "UTC-08:00",
  "Pacific/Pitcairn": "UTC-08:00",
  "Etc/GMT+8": "UTC-08:00",

  // UTC−07:00
  "America/Chihuahua": "UTC-07:00",
  "America/Denver": "UTC-07:00",
  "America/Phoenix": "UTC-07:00",
  "Etc/GMT+7": "UTC-07:00",

  // UTC−06:00
  "America/Chicago": "UTC-06:00",
  "America/Mexico_City": "UTC-06:00",
  "America/Regina": "UTC-06:00",
  "Pacific/Easter": "UTC-06:00",
  "Etc/GMT+6": "UTC-06:00",

  // UTC−05:00
  "America/Bogota": "UTC-05:00",
  "America/Cancun": "UTC-05:00",
  "America/Havana": "UTC-05:00",
  "America/Lima": "UTC-05:00",
  "America/New_York": "UTC-05:00",
  "Etc/GMT+5": "UTC-05:00",

  // UTC−04:00
  "America/Asuncion": "UTC-04:00",
  "America/Caracas": "UTC-04:00",
  "America/Cuiaba": "UTC-04:00",
  "America/Halifax": "UTC-04:00",
  "America/Santiago": "UTC-04:00",
  "Etc/GMT+4": "UTC-04:00",

  // UTC−03:30
  "America/St_Johns": "UTC-03:30",

  // UTC−03:00
  "America/Araguaina": "UTC-03:00",
  "America/Argentina/Buenos_Aires": "UTC-03:00",
  "America/Bahia": "UTC-03:00",
  "America/Belem": "UTC-03:00",
  "America/Fortaleza": "UTC-03:00",
  "America/Godthab": "UTC-03:00",
  "America/Miquelon": "UTC-03:00",
  "America/Montevideo": "UTC-03:00",
  "America/Sao_Paulo": "UTC-03:00",
  "Etc/GMT+3": "UTC-03:00",

  // UTC−02:00
  "America/Noronha": "UTC-02:00",
  "Atlantic/South_Georgia": "UTC-02:00",
  "Etc/GMT+2": "UTC-02:00",

  // UTC−01:00
  "Atlantic/Azores": "UTC-01:00",
  "Atlantic/Cape_Verde": "UTC-01:00",
  "Etc/GMT+1": "UTC-01:00",

  // UTC±00:00
  "Africa/Abidjan": "UTC+00:00",
  "Atlantic/Reykjavik": "UTC+00:00",
  "Etc/GMT": "UTC+00:00",
  "Etc/UTC": "UTC+00:00",
  "Europe/Dublin": "UTC+00:00",
  "Europe/Lisbon": "UTC+00:00",
  "Europe/London": "UTC+00:00",

  // UTC+01:00
  "Africa/Algiers": "UTC+01:00",
  "Africa/Casablanca": "UTC+01:00",
  "Africa/Lagos": "UTC+01:00",
  "Europe/Berlin": "UTC+01:00",
  "Europe/Paris": "UTC+01:00",
  "Etc/GMT-1": "UTC+01:00",

  // UTC+02:00
  "Africa/Cairo": "UTC+02:00",
  "Africa/Johannesburg": "UTC+02:00",
  "Asia/Amman": "UTC+02:00",
  "Asia/Beirut": "UTC+02:00",
  "Asia/Damascus": "UTC+02:00",
  "Asia/Gaza": "UTC+02:00",
  "Europe/Athens": "UTC+02:00",
  "Europe/Helsinki": "UTC+02:00",
  "Europe/Istanbul": "UTC+02:00",
  "Etc/GMT-2": "UTC+02:00",

  // UTC+03:00
  "Africa/Addis_Ababa": "UTC+03:00",
  "Asia/Baghdad": "UTC+03:00",
  "Asia/Qatar": "UTC+03:00",
  "Europe/Kaliningrad": "UTC+03:00",
  "Europe/Moscow": "UTC+03:00",
  "Etc/GMT-3": "UTC+03:00",

  // UTC+03:30
  "Asia/Tehran": "UTC+03:30",

  // UTC+04:00
  "Asia/Baku": "UTC+04:00",
  "Asia/Dubai": "UTC+04:00",
  "Asia/Tbilisi": "UTC+04:00",
  "Europe/Samara": "UTC+04:00",
  "Etc/GMT-4": "UTC+04:00",

  // UTC+04:30
  "Asia/Kabul": "UTC+04:30",

  // UTC+05:00
  "Asia/Karachi": "UTC+05:00",
  "Asia/Tashkent": "UTC+05:00",
  "Etc/GMT-5": "UTC+05:00",

  // UTC+05:30
  "Asia/Colombo": "UTC+05:30",
  "Asia/Kolkata": "UTC+05:30",

  // UTC+05:45
  "Asia/Kathmandu": "UTC+05:45",

  // UTC+06:00
  "Asia/Almaty": "UTC+06:00",
  "Asia/Dhaka": "UTC+06:00",
  "Asia/Yekaterinburg": "UTC+06:00",
  "Etc/GMT-6": "UTC+06:00",

  // UTC+06:30
  "Asia/Rangoon": "UTC+06:30",
  "Indian/Cocos": "UTC+06:30",

  // UTC+07:00
  "Asia/Bangkok": "UTC+07:00",
  "Asia/Ho_Chi_Minh": "UTC+07:00",
  "Asia/Jakarta": "UTC+07:00",
  "Etc/GMT-7": "UTC+07:00",

  // UTC+08:00
  "Asia/Brunei": "UTC+08:00",
  "Asia/Hong_Kong": "UTC+08:00",
  "Asia/Krasnoyarsk": "UTC+08:00",
  "Asia/Kuala_Lumpur": "UTC+08:00",
  "Asia/Macau": "UTC+08:00",
  "Asia/Shanghai": "UTC+08:00",
  "Asia/Singapore": "UTC+08:00",
  "Asia/Taipei": "UTC+08:00",
  "Australia/Perth": "UTC+08:00",
  "Etc/GMT-8": "UTC+08:00",

  // UTC+08:45
  "Australia/Eucla": "UTC+08:45",

  // UTC+09:00
  "Asia/Chita": "UTC+09:00",
  "Asia/Irkutsk": "UTC+09:00",
  "Asia/Seoul": "UTC+09:00",
  "Asia/Tokyo": "UTC+09:00",
  "Etc/GMT-9": "UTC+09:00",

  // UTC+09:30
  "Australia/Adelaide": "UTC+09:30",
  "Australia/Darwin": "UTC+09:30",

  // UTC+10:00
  "Asia/Vladivostok": "UTC+10:00",
  "Australia/Brisbane": "UTC+10:00",
  "Australia/Hobart": "UTC+10:00",
  "Australia/Sydney": "UTC+10:00",
  "Etc/GMT-10": "UTC+10:00",
  "Pacific/Guam": "UTC+10:00",
  "Pacific/Port_Moresby": "UTC+10:00",

  // UTC+10:30
  "Australia/Lord_Howe": "UTC+10:30",

  // UTC+11:00
  "Asia/Magadan": "UTC+11:00",
  "Pacific/Efate": "UTC+11:00",
  "Pacific/Guadalcanal": "UTC+11:00",
  "Etc/GMT-11": "UTC+11:00",

  // UTC+12:00
  "Asia/Anadyr": "UTC+12:00",
  "Asia/Kamchatka": "UTC+12:00",
  "Pacific/Auckland": "UTC+12:00",
  "Pacific/Fiji": "UTC+12:00",
  "Etc/GMT-12": "UTC+12:00",

  // UTC+12:45
  "Pacific/Chatham": "UTC+12:45",

  // UTC+13:00
  "Pacific/Enderbury": "UTC+13:00",
  "Pacific/Fakaofo": "UTC+13:00",
  "Pacific/Tongatapu": "UTC+13:00",
  "Etc/GMT-13": "UTC+13:00",

  // UTC+14:00
  "Pacific/Kiritimati": "UTC+14:00",
  "Etc/GMT-14": "UTC+14:00",
};

export const cronFormConfig: Record<
  WokrflowSchedulerFormFields,
  WorkflowSchedulerFormConfigType
> = {
  [WokrflowSchedulerFormFields.timezone]: {
    label: WORKFLOW_SCHEDULE_TIMEZONE_SELECT_LABEL,
    tooltip: () => "",
    placeholder: () => "",
    validationRules: {
      required: "Can't be empty",
    },
  },
  [WokrflowSchedulerFormFields.workflowScheduleMinute]: {
    label: WORKFLOW_SCHEDULE_INPUT_MINUTE_LABEL,
    tooltip: WORKFLOW_SCHEDULE_INPUT_TOOLTIP,
    placeholder: WORKFLOW_SCHEDULE_INPUT_MINUTE_PLACEHOLDER,
    validationRules: {
      required: createMessage(
        WORKFLOW_SCHEDULE_INPUT_VALIDATION_REQUIRED_ERROR,
      ),
      minLength: {
        value: 1,
        message: createMessage(
          WORKFLOW_SCHEDULE_INPUT_VALIDATION_REQUIRED_ERROR,
        ),
      },
      pattern: {
        message: createMessage(WORKFLOW_SCHEDULE_INPUT_VALIDATION_REGEX_ERROR),
        value:
          /^(\*|([0-5]?\d)(-[0-5]?\d)?)(\/([1-5]?\d))?(,(\*|([0-5]?\d)(-[0-5]?\d)?)(\/([1-5]?\d))?)*$/,
      },
    },
  },
  [WokrflowSchedulerFormFields.workflowScheduleHour]: {
    label: WORKFLOW_SCHEDULE_INPUT_HOUR_LABEL,
    tooltip: WORKFLOW_SCHEDULE_INPUT_TOOLTIP,
    placeholder: WORKFLOW_SCHEDULE_INPUT_HOUR_PLACEHOLDER,
    validationRules: {
      required: createMessage(
        WORKFLOW_SCHEDULE_INPUT_VALIDATION_REQUIRED_ERROR,
      ),
      minLength: {
        value: 1,
        message: createMessage(
          WORKFLOW_SCHEDULE_INPUT_VALIDATION_REQUIRED_ERROR,
        ),
      },
      pattern: {
        message: createMessage(WORKFLOW_SCHEDULE_INPUT_VALIDATION_REGEX_ERROR),
        value:
          /^(?:\*\/\d+|\*|(?:[01]?\d|2[0-3])(?:-(?:[01]?\d|2[0-3]))?)(?:,(?:\*\/\d+|\*|(?:[01]?\d|2[0-3])(?:-(?:[01]?\d|2[0-3]))?))*$/,
      },
    },
  },
  [WokrflowSchedulerFormFields.workflowScheduleDOM]: {
    label: WORKFLOW_SCHEDULE_INPUT_DAY_OF_MONTH_LABEL,
    tooltip: WORKFLOW_SCHEDULE_INPUT_TOOLTIP,
    placeholder: WORKFLOW_SCHEDULE_INPUT_DAY_OF_MONTH_PLACEHOLDER,
    validationRules: {
      required: createMessage(
        WORKFLOW_SCHEDULE_INPUT_VALIDATION_REQUIRED_ERROR,
      ),
      minLength: {
        value: 1,
        message: createMessage(
          WORKFLOW_SCHEDULE_INPUT_VALIDATION_REQUIRED_ERROR,
        ),
      },
      pattern: {
        message: createMessage(WORKFLOW_SCHEDULE_INPUT_VALIDATION_REGEX_ERROR),
        value:
          /^(?:\*\/\d+|\*|(?:[1-9]|[12]\d|3[01])(?:-(?:[1-9]|[12]\d|3[01]))?)(?:,(?:\*\/\d+|\*|(?:[1-9]|[12]\d|3[01])))*$/,
      },
    },
  },
  [WokrflowSchedulerFormFields.workflowScheduleMonth]: {
    label: WORKFLOW_SCHEDULE_INPUT_MONTH_LABEL,
    tooltip: WORKFLOW_SCHEDULE_INPUT_TOOLTIP,
    placeholder: WORKFLOW_SCHEDULE_INPUT_MONTH_PLACEHOLDER,
    validationRules: {
      required: createMessage(
        WORKFLOW_SCHEDULE_INPUT_VALIDATION_REQUIRED_ERROR,
      ),
      minLength: {
        value: 1,
        message: createMessage(
          WORKFLOW_SCHEDULE_INPUT_VALIDATION_REQUIRED_ERROR,
        ),
      },
      pattern: {
        message: createMessage(WORKFLOW_SCHEDULE_INPUT_VALIDATION_REGEX_ERROR),
        value:
          /^(?:\*\/\d+|\*|(?:[1-9]|1[0-2])(?:-(?:[1-9]|1[0-2]))?)(?:,(?:\*\/\d+|\*|(?:[1-9]|1[0-2])(?:-(?:[1-9]|1[0-2]))?))*$/,
      },
    },
  },
  [WokrflowSchedulerFormFields.workflowScheduleDOW]: {
    label: WORKFLOW_SCHEDULE_INPUT_DAY_OF_WEEK_LABEL,
    tooltip: WORKFLOW_SCHEDULE_INPUT_TOOLTIP,
    placeholder: WORKFLOW_SCHEDULE_INPUT_DAY_OF_WEEK_PLACEHOLDER,
    validationRules: {
      required: createMessage(
        WORKFLOW_SCHEDULE_INPUT_VALIDATION_REQUIRED_ERROR,
      ),
      minLength: {
        value: 1,
        message: createMessage(
          WORKFLOW_SCHEDULE_INPUT_VALIDATION_REQUIRED_ERROR,
        ),
      },
      pattern: {
        message: createMessage(WORKFLOW_SCHEDULE_INPUT_VALIDATION_REGEX_ERROR),
        value:
          /^(?:\*\/\d+|\*|(?:[0-7])(?:-(?:[0-7]))?)(?:,(?:\*\/\d+|\*|(?:[0-7])(?:-(?:[0-7]))?))*$/,
      },
    },
  },
};

export const WORKFLOW_SCHEDULE_FORM_DEFAULT_VALUES: WorkflowSchedulerFormDataType =
  {
    [WokrflowSchedulerFormFields.timezone]: "Asia/Kolkata",
    [WokrflowSchedulerFormFields.workflowScheduleMinute]: "0",
    [WokrflowSchedulerFormFields.workflowScheduleHour]: "*",
    [WokrflowSchedulerFormFields.workflowScheduleMonth]: "*",
    [WokrflowSchedulerFormFields.workflowScheduleDOW]: "*",
    [WokrflowSchedulerFormFields.workflowScheduleDOM]: "*",
  };
