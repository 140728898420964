import React from "react";
import { PluginActionToolbar } from "PluginActionEditor";
import { useSaveModuleActionName } from "../hooks/useSaveModuleActionName";
import { PluginActionNameEditor } from "PluginActionEditor";
import { ToolbarMenu } from "./ToolbarMenu";

const ModulePluginActionToolbar = () => {
  const { onSaveActionName } = useSaveModuleActionName();

  return (
    <PluginActionToolbar menuContent={<ToolbarMenu />}>
      <PluginActionNameEditor saveActionName={onSaveActionName} />
    </PluginActionToolbar>
  );
};

export default ModulePluginActionToolbar;
