import React, { useMemo } from "react";
import styled from "styled-components";
import { debounce } from "lodash";
import { Text } from "@appsmith/ads";
import { useDispatch, useSelector } from "react-redux";
import { klona } from "klona";

import Form from "ee/components/InputsForm/Form";
import SectionField from "ee/components/InputsForm/Fields/SectionField";
import { updateModuleInputs } from "ee/actions/moduleActions";
import type { MODULE_EDITOR_TYPE } from "ee/constants/ModuleConstants";
import { MODULE_TYPE, type Module } from "ee/constants/ModuleConstants";
import { generateDefaultInputSection } from "ee/components/InputsForm/Fields/helper";
import {
  getCurrentModule,
  getModuleInputsEvalValues,
} from "ee/selectors/modulesSelector";
import type { Diff } from "deep-diff";

const StyledHeading = styled(Text)`
  margin-bottom: var(--ads-v2-spaces-3);
`;

const InstructionsWrapper = styled.div`
  margin-bottom: var(--ads-v2-spaces-4);
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  border-left: 1px solid var(--ads-v2-color-border);
  padding: var(--ads-v2-spaces-3) var(--ads-v2-spaces-7) var(--ads-v2-spaces-4);
  width: ${(props) => props.theme.actionSidePane.width}px;
`;

const StyledBody = styled.div`
  flex: 1;
  height: 100%;
  margin-top: var(--ads-v2-spaces-4);
  margin-bottom: var(--ads-v2-spaces-4);
  overflow-y: auto;
`;

const StyledExample = styled(Text)`
  display: block;
  font-size: 13px;
  margin-top: var(--ads-v2-spaces-2);
`;

interface DEFAULT_VALUES {
  inputsForm: Module["inputsForm"];
}

interface ModuleInputsFormProps {
  moduleId?: string;
  defaultValues?: DEFAULT_VALUES;
  moduleEditorType?: MODULE_EDITOR_TYPE;
}

const generateDefaultInputForm = () => {
  const defaultInputSection = generateDefaultInputSection();

  return { inputsForm: [defaultInputSection] };
};

const DEBOUNCE_TIMEOUT = 100;

function ModuleInputsForm({
  defaultValues,
  moduleEditorType,
  moduleId,
}: ModuleInputsFormProps) {
  const dispatch = useDispatch();
  const inputsEvaluatedValues = useSelector(
    getModuleInputsEvalValues,
  ) as Record<string, unknown>;

  const currentModule = useSelector(getCurrentModule);

  const blockCompletions = useMemo(() => {
    if (!!currentModule && currentModule.type === MODULE_TYPE.QUERY) {
      return [
        {
          parentPath: `${currentModule?.name}`,
          subPath: `${currentModule?.name}`,
        },
        {
          parentPath: `${currentModule?.name}`,
          subPath: `${currentModule?.name}.run()`,
        },
        {
          parentPath: `${currentModule?.name}`,
          subPath: `${currentModule?.name}.data`,
        },
        {
          parentPath: `${currentModule?.name}`,
          subPath: `${currentModule?.name}.responseMeta`,
        },
        {
          parentPath: `${currentModule?.name}`,
          subPath: `${currentModule?.name}.clear()`,
        },
        {
          parentPath: `${currentModule?.name}`,
          subPath: `${currentModule?.name}.isLoading`,
        },
      ];
    }

    return [];
  }, [currentModule?.name]);

  const onUpdateInputsForm = useMemo(() => {
    const onUpdate = (
      values: { inputsForm: Module["inputsForm"] },
      diff?: Diff<unknown, unknown>[],
    ) => {
      if (!moduleId) return;

      const newValues = klona(values.inputsForm);

      newValues.forEach((section) => {
        section.children.forEach((sectionProperties) => {
          sectionProperties.propertyName = `inputs.${sectionProperties.label}`;
        });
      });

      dispatch(
        updateModuleInputs({
          id: moduleId,
          inputsForm: newValues,
          diff,
          moduleEditorType,
        }),
      );
    };

    return debounce(onUpdate, DEBOUNCE_TIMEOUT);
  }, [updateModuleInputs, moduleId, dispatch, moduleEditorType]);

  return (
    <StyledWrapper>
      <StyledHeading className="label" kind="heading-s">
        Inputs
      </StyledHeading>
      <StyledBody data-testid="t--module-inputs-form">
        <InstructionsWrapper>
          <Text>Access the inputs defined below using the syntax</Text>
          <StyledExample kind="code">{`{{ inputs.inputName }}`}</StyledExample>
        </InstructionsWrapper>
        <Form
          blockCompletions={blockCompletions}
          dataTreePathPrefix="inputs"
          defaultValues={defaultValues || generateDefaultInputForm()}
          evaluatedValues={inputsEvaluatedValues}
          onUpdateForm={onUpdateInputsForm}
        >
          <SectionField name="inputsForm" />
        </Form>
      </StyledBody>
    </StyledWrapper>
  );
}

export default ModuleInputsForm;
