import type {
  DataTreeEntityObject,
  ModuleInstanceEntity,
} from "ee/entities/DataTree/types";
import type { EntityProperty } from "./getEntityProperties";
export * from "ce/pages/Editor/Explorer/Entity/getEntityProperties";
import { getEntityPropertiesMap as CE_getEntityPropertiesMap } from "ce/pages/Editor/Explorer/Entity/getEntityProperties";
import { ENTITY_TYPE } from "ee/entities/DataTree/types";
import { MODULE_TYPE } from "ee/constants/ModuleConstants";
import store from "store";
import { getAllJSCollections } from "ee/selectors/entitiesSelector";

const getModuleInstanceBindings = (
  entity: DataTreeEntityObject,
  entityProperties: EntityProperty[],
  entityType: string,
  entityName: string,
) => {
  const moduleInstanceEntity = entity as ModuleInstanceEntity;
  const type = moduleInstanceEntity.type;

  switch (type) {
    case MODULE_TYPE.QUERY:
      entityProperties = getEntityPropertiesMap[ENTITY_TYPE.ACTION](
        entity,
        entityProperties,
        entityType,
        entityName,
      );
      break;
    case MODULE_TYPE.JS:
      const state = store.getState();

      const jsActions = getAllJSCollections(state);
      const jsCollection = jsActions.find(
        // @ts-expect-error userExecutableName type not defined
        (js) => js.config?.userExecutableName === entityName,
      );

      const actions = jsCollection?.config.actions;

      if (actions && actions.length > 0)
        for (let i = 0; i < jsCollection.config.actions.length; i++) {
          const action = jsCollection.config.actions[i];

          entityProperties.push({
            propertyName: action.name + "()",
            entityName: entityName,
            value: "Function",
            entityType,
          });

          entityProperties.push({
            propertyName: action.name + ".data",
            entityName: entityName,
            value: jsCollection?.data?.[action.id],
            entityType,
          });
        }

      break;
  }

  return entityProperties;
};

export const getEntityPropertiesMap: Record<
  string,
  (
    entity: DataTreeEntityObject,
    // TODO: Fix this the next time the file is edited
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    entityProperties: any,
    entityType: string,
    entityName: string,
  ) => EntityProperty[]
> = {
  ...CE_getEntityPropertiesMap,
  [ENTITY_TYPE.MODULE_INSTANCE]: getModuleInstanceBindings,
};

export function getEntityProperties({
  entity,
  entityName,
  entityType,
}: {
  entityType: string;
  entity: DataTreeEntityObject;
  entityName: string;
}) {
  let entityProperties: EntityProperty[] = [];

  if (entityType in getEntityPropertiesMap) {
    entityProperties = getEntityPropertiesMap[entityType](
      entity,
      entityProperties,
      entityType,
      entityName,
    );
  }

  return entityProperties;
}
