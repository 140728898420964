import React from "react";
import { DocsMenuItem, usePluginActionContext } from "PluginActionEditor";
import { MenuSeparator } from "@appsmith/ads";
import { DeleteWorkflowMenuItem } from "./DeleteWorkflowMenuItem";
import { DuplicateWorkflowMenuItem } from "./DuplicateWorkflowMenuItem";
import { RenameMenuItem } from "IDE";

export const ToolbarMenu = () => {
  const { action } = usePluginActionContext();

  return (
    <>
      <RenameMenuItem entityId={action.id} />
      <DocsMenuItem />
      <DuplicateWorkflowMenuItem />
      <MenuSeparator />
      <DeleteWorkflowMenuItem />
    </>
  );
};
