import React from "react";
import {
  PluginActionEditor,
  PluginActionForm,
  PluginActionResponse,
} from "PluginActionEditor";
import ModulePluginActionToolbar from "ee/pages/Editor/ModuleEditor/ModulePluginActionEditor/components/ModulePluginActionToolbar";
import { useLocation } from "react-router";
import { identifyEntityFromPath } from "navigation/FocusEntity";
import { Flex } from "@appsmith/ads";
import ModuleInputsForm from "../ModuleInputsForm";
import { useSelector } from "react-redux";
import { getModuleById } from "ee/selectors/modulesSelector";
import { MODULE_EDITOR_TYPE } from "ee/constants/ModuleConstants";
import { PluginType } from "entities/Action";
import { getActionByBaseId } from "ee/selectors/entitiesSelector";

const ModulePluginActionEditor = () => {
  const { pathname } = useLocation();
  const entity = identifyEntityFromPath(pathname);

  const module = useSelector((state) =>
    getModuleById(state, entity.params?.moduleId || ""),
  );

  const action = useSelector((state) => getActionByBaseId(state, entity.id));

  const moduleEditorType = module
    ? [PluginType.API].includes(module?.pluginType)
      ? MODULE_EDITOR_TYPE.API
      : MODULE_EDITOR_TYPE.QUERY
    : undefined;

  return (
    <PluginActionEditor actionId={entity.id}>
      <ModulePluginActionToolbar />
      <Flex flex="1" overflow="hidden" w="100%">
        <PluginActionForm />
        {module?.inputsForm && action?.isPublic && (
          <ModuleInputsForm
            defaultValues={{ inputsForm: module?.inputsForm }}
            moduleEditorType={moduleEditorType}
            moduleId={entity.params?.moduleId}
          />
        )}
      </Flex>
      <PluginActionResponse />
    </PluginActionEditor>
  );
};

export default ModulePluginActionEditor;
