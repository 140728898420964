export * from "ce/pages/Editor/IDE/MainPane/useRoutes";
import { default as useCE_Routes } from "ce/pages/Editor/IDE/MainPane/useRoutes";
import type { RouteReturnType } from "ce/pages/Editor/IDE/MainPane/useRoutes";
import {
  MODULE_INSTANCE_ID_PATH,
  ADD_PATH,
} from "ee/constants/routes/appRoutes";
import { useSelector } from "react-redux";
import { getShowQueryModule } from "ee/selectors/moduleFeatureSelectors";
import WidgetsEditor from "pages/Editor/WidgetsEditor";

function useRoutes(path: string) {
  const ceRoutes = useCE_Routes(path);
  const showQueryModule = useSelector(getShowQueryModule);
  let moduleRoutes: RouteReturnType[] = [];

  if (showQueryModule) {
    moduleRoutes = [
      // Module instance is on App IDE, needs canvas to be shown
      {
        key: "ModuleInstance",
        component: WidgetsEditor,
        exact: true,
        path: [
          `${path}${MODULE_INSTANCE_ID_PATH}`,
          `${path}${MODULE_INSTANCE_ID_PATH}${ADD_PATH}`,
        ],
      },
    ];
  }

  return [...ceRoutes, ...moduleRoutes];
}

export default useRoutes;
