import React from "react";
import { Callout } from "@appsmith/ads";

import { createMessage, PRIVATE_ENTITY_DEPRECATE } from "ee/constants/messages";

const PACKAGE_GUIDE_URL =
  "https://appsmith-docs-git-new-package-2-get-appsmith.vercel.app/packages/how-to-guides/pass-date-between-modules";

const LINKS = [
  {
    children: "Learn more",
    to: PACKAGE_GUIDE_URL,
    target: "_blank",
  },
];

function PrivateEntityDeprecationCallout() {
  return (
    <Callout isClosable kind="info" links={LINKS}>
      {createMessage(PRIVATE_ENTITY_DEPRECATE)}
    </Callout>
  );
}

export default PrivateEntityDeprecationCallout;
