import { getModuleInstances } from "ee/selectors/entitiesSelector";
import { getAllModules } from "ee/selectors/modulesSelector";
import { getPackages } from "ee/selectors/packageSelectors";
import { useSelector } from "react-redux";

export interface ConsumedPackage {
  packageId: string;
  name: string;
  version: string;
  // versions: string[];
}

function useConsumedPackages() {
  const moduleInstances = useSelector(getModuleInstances);
  const modules = useSelector(getAllModules);
  const packages = useSelector(getPackages);
  const consumedPackages: Record<string, ConsumedPackage> = {};

  Object.values(moduleInstances).map((moduleInstance) => {
    const module = modules[moduleInstance.sourceModuleId];
    const { invalidState, isValid } = moduleInstance;

    if (module) {
      const pkg = packages[module.packageId];

      if (pkg) {
        consumedPackages[pkg.id] = {
          packageId: pkg.id,
          name: pkg.name,
          version: module.version,
        };
      }
    } else if (!isValid && invalidState) {
      const { originPackageId, packageName } = invalidState;
      const pkg = Object.values(packages).find(
        (p) => p.originPackageId === originPackageId,
      );

      if (pkg) {
        consumedPackages[pkg.id] = {
          packageId: pkg.id,
          name: pkg.name,
          version: pkg.activeVersion,
        };
      } else if (packageName) {
        consumedPackages[packageName] = {
          packageId: packageName,
          name: packageName,
          version: "-",
        };
      }
    }
  });

  return consumedPackages;
}

export default useConsumedPackages;
