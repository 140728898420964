import React, { useMemo, useState } from "react";
import { Button, Callout, Input, Switch, Text, toast } from "@appsmith/ads";
import styled from "styled-components";
import {
  WEBHOOK_TRIGGER_SWITCH_LABEL,
  WEBHOOK_TRIGGER_TOKEN_WARNING,
  createMessage,
  WORKFLOW_REGENERATE_CTA,
  WEBHOOK_TRIGGER_SWITCH_DESC,
  WEBHOOK_TRIGGER_TOKEN_LABEL,
  WORKFLOW_COPY_CURL_CTA,
  WORKFLOW_COPY_CONFIRM_CTA,
} from "ee/constants/messages";
import AnalyticsUtil from "ee/utils/AnalyticsUtil";
import { useDispatch, useSelector } from "react-redux";
import copy from "copy-to-clipboard";
import {
  getIsWorkflowTokenDetails,
  getIsWorkflowTokenGenerating,
} from "ee/selectors/workflowSelectors";
import { toggleWorkflowToken } from "ee/actions/workflowActions";
import { truncateString } from "utils/helpers";
import { RegenerateTokenModal } from "./RegenerateTokenModal";

const InputWrapper = styled.div<{
  alignCenter: boolean;
}>`
  display: flex;
  align-items: ${(props) => `${props.alignCenter ? "center" : "start"}`};
  gap: 16px;
`;

const TokenInfoWrapper = styled.div`
  display: block;
  margin-top: 8px;
`;

interface IProps {
  currentWorkflowId?: string;
}

export default function WebhookSettingsSection({ currentWorkflowId }: IProps) {
  const isWorkflowTokenGenerating = useSelector(getIsWorkflowTokenGenerating);
  const [isRegenerateModalOpen, setIsRegenerateModalOpen] = useState(false);
  const dispatch = useDispatch();

  function onCloseRegenerateModal() {
    setIsRegenerateModalOpen(false);
  }

  const { token, tokenGenerated: isWorkflowTokenGenerated } = useSelector(
    getIsWorkflowTokenDetails,
  );

  const host =
    (!!window &&
      !!window.location &&
      !!window.location.host &&
      window.location.host) ||
    "app.appsmith.com";
  const { curlCmd, path } = useMemo(() => {
    const suffix = token.length > 0 ? token : "*****";
    const path = `https://${host}/api/v1/workflows/trigger/${currentWorkflowId}?api-key=${suffix}`;
    const curlCmd = `curl -XPOST -H "Content-type: application/json" '${path}'`;

    return { path, curlCmd };
  }, [host, currentWorkflowId, token]);

  function handleCopyAction(data: string) {
    if (token.length === 0) return;

    AnalyticsUtil.logEvent("COPY_WORKFLOW_WEBHOOK", {
      workflowId: currentWorkflowId,
      copy: data,
    });
    toast.show(createMessage(WORKFLOW_COPY_CONFIRM_CTA), {
      kind: "success",
    });
    copy(data);
  }

  function onSwitchStateChange() {
    const analyticsEvent = isWorkflowTokenGenerated
      ? "ENABLE_WORKFLOW_WEBHOOK"
      : "DISABLE_WORKFLOW_WEBHOOK";

    AnalyticsUtil.logEvent(analyticsEvent, {
      workflowId: currentWorkflowId,
    });
    currentWorkflowId &&
      dispatch(
        toggleWorkflowToken(currentWorkflowId, isWorkflowTokenGenerated),
      );
  }

  return (
    <>
      <div className="flex-col justify-between content-center">
        <Switch
          data-testid="t--workflow-token-generation-switch"
          // We are disabling the switch if the workflow token is generated
          isDisabled={isWorkflowTokenGenerating}
          isSelected={isWorkflowTokenGenerated}
          onChange={onSwitchStateChange}
        >
          <Text kind="heading-s">
            {createMessage(WEBHOOK_TRIGGER_SWITCH_LABEL)}
          </Text>
        </Switch>
        <Text kind="body-m">{createMessage(WEBHOOK_TRIGGER_SWITCH_DESC)}</Text>
        {isWorkflowTokenGenerated && (
          <>
            {token.length > 0 && (
              <TokenInfoWrapper className="mt-[16px]">
                <Callout kind="warning">
                  {createMessage(WEBHOOK_TRIGGER_TOKEN_WARNING)}
                </Callout>
              </TokenInfoWrapper>
            )}
            <InputWrapper alignCenter={false} className="mt-[16px]">
              <Input
                // USAFE_height is set to 60px to remove excess space at bottom of textarea
                UNSAFE_height="60px"
                aria-multiline
                data-testid="t--workflow-token-url"
                description={createMessage(WEBHOOK_TRIGGER_TOKEN_LABEL)}
                isDisabled
                label="URL"
                renderAs="textarea"
                size="md"
                value={truncateString(path, 148)}
              />
              <Button
                className="mt-[24px]"
                data-testid="t--workflow-copy-url-button"
                isDisabled={isWorkflowTokenGenerating || token.length === 0}
                isIconButton
                kind="tertiary"
                onClick={() => handleCopyAction(path)}
                size="md"
                startIcon="copy-control"
              />
            </InputWrapper>

            <InputWrapper
              alignCenter={token.length === 0}
              className="mt-[16px]"
            >
              <Button
                data-testid="t--workflow-copy-curl-button"
                isDisabled={isWorkflowTokenGenerating || token.length === 0}
                kind={token.length === 0 ? "secondary" : "primary"}
                onClick={() => handleCopyAction(curlCmd)}
                size="md"
              >
                {createMessage(WORKFLOW_COPY_CURL_CTA)}
              </Button>
              {token.length === 0 && (
                <Button
                  data-testid="t--workflow-regenerate-button"
                  isLoading={isWorkflowTokenGenerating}
                  kind={token.length === 0 ? "primary" : "secondary"}
                  onClick={() => setIsRegenerateModalOpen(true)}
                  size="md"
                >
                  {createMessage(WORKFLOW_REGENERATE_CTA)}
                </Button>
              )}
            </InputWrapper>
          </>
        )}
      </div>
      <RegenerateTokenModal
        data-testid="t--workflow-regenerate-token-modal"
        isOpen={isRegenerateModalOpen}
        isWorkflowTokenGenerating={isWorkflowTokenGenerating}
        onClose={onCloseRegenerateModal}
        onRegenerate={() =>
          dispatch(toggleWorkflowToken(currentWorkflowId || "", false))
        }
        token={token}
      />
    </>
  );
}
